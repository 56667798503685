import React, { useState, useEffect } from 'react';

import { 
  useParams,
} from 'react-router-dom';

import withAuthorization from './withAuthorization';

import * as api from '../api';

import Sidebar from './Sidebar';
import HooksTable from './HooksTable';

import { AnchorButton } from "@blueprintjs/core";


function ProjectHooksPage() {
  const { id } = useParams();
  const [projectID, setProjectID] = useState(id);
  const [projectDomain, setProjectDomain] = useState('');

  useEffect(() => {
    api.projects.getProjects().then(results => {
      var projectDomain;
      for(var project of results) {
        if (project.id === id) {
          projectDomain = project.domain;
        }
      }
      setProjectID(id);
      setProjectDomain(projectDomain);
    }).catch(error => {
      console.error(error);
    });
  }, [id]);

  return (
    <div className="dt-ns w-100 center">
      <Sidebar />
      <section className="pa4-ns pa1 dtc-ns center w-100 w-80-ns">
        <div className="tr">
          <div className="dib mr3 v-mid">
          { projectDomain &&
              <h3>https://{projectDomain}</h3>
          }
          </div>
          <div className="dib">
            { projectID &&
              <AnchorButton className="ml2" icon="settings" text="Settings" href={`/app/projects/${projectID}`} />
            }
          </div>
        </div>
        <article className="black-80 center mv4 ba b--black-70 br2 br--top bg-navy shadow-4" id="pending">
          <h2 className="tc f5 white mv0 pv2 ph3">Pending</h2>
          <div className="bg-white black pa3 bt b--black-70">
            <HooksTable projectID={projectID} status={"pending"} postAtSortOrder={"ASC"} />
          </div>
        </article>
        <article className="black-80 center mv4 ba b--red br2 br--top bg-red shadow-4" id="failed">
          <h2 className="tc f5 white mv0 pv2 ph3">Failed</h2>
          <div className="bg-white black pa3 bt b--red">
            <HooksTable projectID={projectID} status={"failed"} postAtSortOrder={"ASC"} />
          </div>
        </article>
        <article className="black-80 center mv4 ba b--green br2 br--top bg-green shadow-4" id="completed">
          <h2 className="tc f5 white mv0 pv2 ph3">Completed</h2>
          <div className="bg-white black pa3 bt b--green">
            <HooksTable projectID={projectID} status={"completed"} postAtSortOrder={"ASC"} />
          </div>
        </article>
      </section>
    </div>
  );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ProjectHooksPage);