import React, { Component } from 'react';
import withAuthorization from './withAuthorization';
import { Link } from 'react-router-dom';

import Sidebar from './Sidebar';
import * as routes from '../constants/routes';

import { projects } from '../api';


const INITIAL_STATE = {
  projects: [
  ]
}

class ProjectsPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    projects.getProjects().then(result => {
      this.setState({
        projects: result
      });
    }).catch(error => {
      console.error(error);
    });
  }

  render() {
    return (
      <div className="dt-ns w-100 center mw9">
        <Sidebar />
        <section className="pa4 dtc-ns center w-100 w-80-ns mw7">
          <article className="black-80 center mv4 ba b--black-70 br2 br--top bg-navy shadow-4" id="projects">
            <h2 className="tc f5 white mv0 pv2 ph3">Projects</h2>
            <div className="pv4 bg-near-white black pa3 bt b--black-70">
              <div className="overflow-auto w-100">
                <table className="f6 w-100" cellSpacing="0">
                  <thead>
                    <tr>
                      <th className="fw6 bb b--black-20 tc pb3 pr3">Name</th>
                      <th className="fw6 bb b--black-20 tc pb3 pr3">Domain</th>
                    </tr>
                  </thead>
                  <tbody className="lh-copy">
                    {
                      this.state.projects.map((project) => {
                        return (
                          <tr key={project.apiKeyID}>
                            <td className="pv3 pr3 bb b--black-20 tc"><Link to={routes.PROJECTS + '/' + project.id} className="blue link">{project.name}</Link></td>
                            <td className="pv3 pr3 bb b--black-20 tc">{project.domain}</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              <Link className="link" to={routes.PROJECTS_NEW}><button className="mt3 center dt-ns ph3 pv1 button-reset ba b--blue bg-blue white pointer f5 b br1">Create Project</button></Link>
            </div>
          </article>
        </section>
      </div>
    )
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ProjectsPage);