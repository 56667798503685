import React, { Component } from 'react';
import { auth } from '../firebase';

const PasswordForgetPage = () =>
    <div className="w-100 ph3 mw6 pv3 center bg-white ba b--black-10 ma5">
        <h1 className="f3 fw5 tc">Forgot your password?</h1>
        <p className="mt4">Submit your email address below and we'll send you a link to reset your password.</p>
        <PasswordForgetForm />
    </div>

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    email: '',
    error: null,
};

class PasswordForgetForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (event) => {
        const { email } = this.state;

        auth.doPasswordReset(email)
            .then(() => {
                this.setState(() => ({ ...INITIAL_STATE }));
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });

        event.preventDefault();
    }

    render() {
        const {
            email,
            error,
        } = this.state;

        const isInvalid = email === '';

        return (
            <div>
            <article className="black-80">
            <form onSubmit={this.onSubmit}>
                <fieldset className="ba b--transparent ph0 mh0">
                <legend className="ph0 mh0 fw6 clip">Forgot Password</legend>
                <div className="mt3">
                    <label className="db fw5 lh-copy f5" for="email-address">Email Address</label>
                    <input className="pa2 input-reset ba bg-white w-100 measure br2"
                        value={this.state.email}
                        onChange={event => this.setState(byPropKey('email', event.target.value))}
                        type="email"
                        placeholder="email@example.com"
                    />
                </div>
                </fieldset>
                    <div className="dib w-100"><button className="w-100 b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1" disabled={isInvalid} type="submit">
                    Reset My Password
                    </button></div>

                {error && <p>{error.message}</p>}
            </form>
            </article>
            </div>
        );
    }
}


export default PasswordForgetPage;
