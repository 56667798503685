import React, { Component } from 'react';

import AuthUserContext from './AuthUserContext';

import withAuthorization from './withAuthorization';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';

import { hooks, projects, tokens } from '../api';

import Sidebar from './Sidebar';
import { API_BASE_URL } from '../config';

import { SecsToDisplayStr } from '../helpers';


const INITIAL_STATE = {
    totalCount: 0,
    pendingCount: 0,
    failedCount: 0,
    hourAvgResponseTimeSeconds: 0,
    dayAvgResponseTimeSeconds: 0,
    weekAvgResponseTimeSeconds: 0,
    project: {},
    apiKey: {
        token: '',
    }
};

class DashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        projects.getProjects().then(result => {
            this.setState({
                project: result[0]
            });

            tokens.getAPIKey(result[0].apiKeyID).then(apiKey => {
                this.setState({
                    apiKey: apiKey
                });
            })

            hooks.getHookMetrics().then(result => {
                this.setState(() => ({
                    totalCount: result.totalCount,
                    pendingCount: result.pendingCount,
                    failedCount: result.failedCount,
                    hourAvgResponseTimeSeconds: result.hourAvgResponseTimeSeconds,
                    dayAvgResponseTimeSeconds: result.dayAvgResponseTimeSeconds,
                    weekAvgResponseTimeSeconds: result.weekAvgResponseTimeSeconds
                }))
            }).catch(error => {
                console.error(error)
            });
        }).catch(error => {
            console.error(error);
        });

        this.refreshPostAt();
    }

    refreshPostAt() {
        const now = new Date()
        const oneMinute = new Date(now.getTime() + 1 * 60000);
        const postAt = oneMinute.toISOString();
        this.setState({
            postAt: postAt
        })
    }

    render() {
        const { totalCount, pendingCount, failedCount, hourAvgResponseTimeSeconds, dayAvgResponseTimeSeconds, weekAvgResponseTimeSeconds, project, apiKey, postAt } = this.state;

        const hourStr = SecsToDisplayStr(hourAvgResponseTimeSeconds);
        const dayStr = SecsToDisplayStr(dayAvgResponseTimeSeconds);
        const weekStr = SecsToDisplayStr(weekAvgResponseTimeSeconds);

        return (
            <AuthUserContext.Consumer>
                {authUser =>
            <div className="db dt-ns w-100 center mw9">
                <Sidebar/>
                <section className="pa4 dtc-ns center w-100 w-80-ns mw7">
                    <div className="w-100">
                        <article className="center mw7-ns mv4 ba b--black-70 br2 br--top bg-navy shadow-4">
                            <h1 className="tc f5 white mv0 pv2 ph3">Get Started</h1>
                            <div className="bg-near-white pa3 bt b--black-70">
                                <p className="b f5 lh-copy">{"Thank you for signing up! We've set up a default "}<Link className="link blue underline" to={routes.PROJECTS + '/' + project.id}>project</Link> to get you started. Run this in your terminal to schedule your first hook for about a minute from now.</p>
                                <div className="db bg-dark-gray white br2 v-mid shadow-5">
                                    <pre className="pa3 pre"><code>{`curl ${API_BASE_URL}/hooks \\
    -H "X-API-Key: ${apiKey.token}" \\
    -H "Content-Type: application/json" \\
    -d '{
            "path": "/webhooks/ph/getStarted",
            "postAt": "${postAt}",
            "data": {
                "email": "${authUser.email}"
            }
        }'`}</code></pre>
                                </div>
                                <div>
                                    <p className="b f5 lh-copy">Check out the <a className="link blue underline" rel="noopener noreferrer" target="_blank" href="https://docs.posthook.io/#f8d16c35-43d4-4b56-850b-4380e8b1eca3">documentation</a> for more details about this endpoint.</p>
                                    <p className="b f5 lh-copy">When you are ready to handle requests from Posthook, change the domain to point to your application for your <Link className="link blue underline" to={routes.PROJECTS + '/' + project.id}>project</Link>. You can also add an email address to be notified if a scheduled request fails.</p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="fl w-50-ns w-100 pa2-ns">
                        <article className="tc center mw6-ns mv4 ba b--black-70 br2 br--top bg-navy shadow-4">
                            <h1 className="f5 white mv0 pv2 ph3">Hooks</h1>
                            <div className="bg-near-white bt b--black-70">
                                <div className="dib ph3 mv3">
                                    <p className="f4 db lh-copy tc black">Total</p>
                                    <hr className="mw3 bb bg-black b--black" />
                                    <p className="f2 db tc black"><span className="f2 black">{totalCount}</span></p>
                                </div>
                                <div className="dib ph3 mv3">
                                    <p className="f4 db lh-copy tc black">Pending</p>
                                    <hr className="mw3 bb bg-black b--black" />
                                    <p className="f2 db tc black"><span className="f2 black">{pendingCount}</span></p>
                                </div>
                                <div className="dib ph3 mv3">
                                    <p className="f4 db lh-copy tc black">Failed</p>
                                    <hr className="mw3 bb bg-black b--black" />
                                    <p className="f2 db tc black"><span className="f2 black">{failedCount}</span></p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="fl w-50-ns w-100 pa2-ns">
                        <article className="tc center mw6-ns mv4 ba b--black-70 br2 br--top bg-navy shadow-4">
                            <h1 className="f5 white mv0 pv2 ph3">Webhook Response Times</h1>
                            <div className="bg-near-white bt b--black-70">
                                <div className="dib ph3 mv3">
                                    <p className="f4 db lh-copy tc black">Hour</p>
                                    <hr className="mw3 bb bg-black b--black" />
                                    <p className="f2 db tc black"><span className="f2 black">{hourStr}</span></p>
                                </div>
                                <div className="dib ph3 mv3">
                                    <p className="f4 db lh-copy tc black">Day</p>
                                    <hr className="mw3 bb bg-black b--black" />
                                    <p className="f2 db tc black"><span className="f2 black">{dayStr}</span></p>
                                </div>
                                <div className="dib ph3 mv3">
                                    <p className="f4 db lh-copy tc black">Week</p>
                                    <hr className="mw3 bb bg-black b--black" />
                                    <p className="f2 db tc black"><span className="f2 black">{weekStr}</span></p>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
            </div>
            }
        </AuthUserContext.Consumer>
        );
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(DashboardPage);