import { firebase } from '../firebase';
import { API_BASE_URL } from '../config';

import axios from 'axios';

export function getTokens() {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/auth/tokens`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      });
    })
}

export function getAPIKey(id) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/auth/tokens`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        const apiKeys = response.data.data.apiKeys;
        for (let i = 0; i < apiKeys.length; i++) {
          if (apiKeys[i].id === id) {
            return apiKeys[i];
          }
        }
        throw new Error("api key not found for id");
      }).catch((error) => {
        console.error(error);
      });
    })
}

export function createAPIKey(domain) {
  // Force a refresh in case they verify their email
  return firebase.auth.currentUser.getIdToken(true)
    .then(token => {
      return axios({
        method: 'post',
        url: `${API_BASE_URL}/auth/tokens/apiKeys`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          domain: domain
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            throw new Error(error.response.data.error);
          }
        } else {
          // Something else happened
          console.error('Error', error);
          throw new Error('An unexpected error occurred. Please try again.');
        }
      });
    })
}

export function deleteAPIKey(id) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'delete',
        url: `${API_BASE_URL}/auth/tokens/apiKeys/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      });
    })
}