import React, { useEffect, useState } from 'react';
import { 
    useLocation, useNavigate
} from 'react-router-dom';

import * as routes from '../constants/routes';
import { auth } from '../firebase';
import { PlanFromLocation } from '../helpers';

function SignUpPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [plan, setPlan] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setPlan(PlanFromLocation(location))
    }, []);

    function signUpErrorMessage(error) {
        var errorMessage;
        switch (error.code) {
            case 'auth/email-already-in-use':
                errorMessage = "Email address is already in use.";
                break;
            case 'auth/invalid-email':
                errorMessage = "Email address is not valid.";
                break;
            case 'auth/weak-password':
                errorMessage = "The password must be at least 6 characters long.";
                break;
            default:
                errorMessage = error.message;
        }

        return errorMessage;
    }

    const onSubmit = (event) => {
        event.preventDefault();
        
        setIsLoading(true);

        const next = plan !== '' ? `${routes.CHECKOUT}?plan=${plan.key}` : routes.DASHBOARD;

        auth.doCreateUserWithEmailAndPassword(email, password)
            .then(authUser => {
                auth.doSendEmailVerification().then(() => {
                    navigate(next);
                }).catch(error => {
                    console.error(error);
                    navigate(next);
                })
            }).catch(error => {
                setErrorMessage(signUpErrorMessage(error));
                setIsLoading(false);
            });
    }

    const isInvalid = password === '' || email === '';

    const buttonText = isLoading ? "Signing you up..." : "Sign Up";
    
    const buttonClassName = isLoading || isInvalid || !agreeTerms ?
            "w-100 b ph3 pv2 input-reset ba b--light-blue bg-light-blue white  f5 br1" :
            "w-100 b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1";

    return (
        <div className="w-100 ph3 mw6 pv3 center bg-white ba b--black-10 mt4">
            <h1 className="f3 fw5 tc">{ plan === '' ? 'Get started with a free account' : 'Get started with a free 15-day trial'}</h1>
            <h2 className="f5 fw3 tc">Create an account to begin scheduling webhooks.</h2>
            <div>
                <article className="black-80">
                    <form onSubmit={onSubmit}>
                        <fieldset className="ba b--transparent ph0 mh0">
                            <legend className="ph0 mh0 fw6 clip">Sign Up</legend>
                            <div className="mt3">
                                <label className="db fw5 lh-copy f5" htmlFor="email-address">Email</label>
                                <input className="pa2 input-reset ba bg-white w-100 br2" name="email" id="email"
                                    value={email} onChange={event => setEmail(event.target.value)}
                                    placeholder="email@example.com" />
                            </div>
                            <div className="mt3">
                                <label className="db fw5 lh-copy f5" htmlFor="password">Password</label>
                                <input className="pa2 input-reset ba bg-white w-100 br2" type="password" name="password" id="password"
                                    value={password} onChange={event => setPassword(event.target.value)} />
                            </div>
                            <div className="mt3 center tc">
                                <input className="mr2 v-mid" type="checkbox" name="agree_terms" id="agree_terms"
                                    value={agreeTerms} onChange={event => setAgreeTerms(event.target.value)} />
                                <label className="fw5 f6 lh-copy v-mid" htmlFor="agree_terms">I agree to the <a className="link blue" target="_blank" rel="noopener noreferrer" href="/terms">Terms of Service</a> and <a className="link blue" target="_blank" rel="noopener noreferrer" href="/terms#privacy">Privacy Policy</a>.</label>
                            </div>
                        </fieldset>
                        <div className="dib mt2 w-100">
                            <button disabled={isLoading || isInvalid || !agreeTerms} className={buttonClassName} type="submit">{buttonText}</button>
                        </div>
                        {errorMessage && <p className="red mt3">{errorMessage}</p>}
                    </form>
                </article>
            </div>
        </div>
    );
}


export default SignUpPage;
