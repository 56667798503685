import React from 'react'

import Navigation from './Navigation';

import logo from '../logo.svg'

export default () => (
  <header className="w-100 pa3 ph5-l bg-white">
    <div className="db dt-l mw9 center w-100">
      <div className="db dtc-l w-third-l"></div>
      <div className="db dtc-l v-mid tc w-100 w-third-l">
        <a href="/" className="dib f5 f4-l fw6 mt0 link" title="Home">
          <img src={logo} width="200" className="" alt="posthook"></img>
        </a>
      </div>
      <Navigation />
    </div>
  </header>
)