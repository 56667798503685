import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import * as routes from '../constants/routes';

import { Home, User, Mail, Layers } from 'react-feather';

import * as api from '../api';

import withAuthorization from './withAuthorization';


function Sidebar() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    api.projects.getProjects().then(result => {
      setProjects(result);
    }).catch(error => {
      console.error(error);
    });
  }, []);

  const getClassName = (className, isActive) => {
    return isActive ? `blue ${className}` : className;
  }

  return (
    <section className="db dtc-l w-100 w-20-l mw5">
      <div className="">
        <span className="db pa1">
          <NavLink
            className={({isActive}) => getClassName("w-auto db pa2 link black f5 input-reset outline-transparent", isActive)}
            to={routes.DASHBOARD}>
              < Home height="18" width="18" className="fl pr2 v-btm" /><span className="v-btm">Dashboard</span>
          </NavLink>
        </span>
        <li className="db pa1">
          <NavLink
            className={({isActive}) => getClassName("db pa2 link mid-gray f5", isActive)}
            to={routes.PROJECTS}>
              <Layers height="18" width="18" className="fl v-mid pr2" />Projects
          </NavLink>
          {
            projects.map((project) => {
              return (
                <NavLink key={project.id}
                  className={({isActive}) => getClassName("db pa2 link black f6", isActive)}
                  to={`${routes.PROJECTS}/${project.id}/hooks`}>
                    <svg height="20" width="20" className="fl pr2 v-btm"></svg><span className="v-btm">{project.name}</span>
                </NavLink>
              );
            })
          }
        </li>
        <li className="db pa1">
          <NavLink
            className={({isActive}) => getClassName("db pa2 link black f5", isActive)}
            to={routes.ACCOUNT}>
              < User height="18" width="18" className="fl pr2 v-btm" /><span className="v-btm">Account</span>
          </NavLink>
        </li>
        <li className="db pa1 pt4">
          <a className="db pa2 link black f5" href="/support">< Mail height="18" width="18" className="fl pr2 v-mid" />Contact Support</a>
        </li>
      </div>
    </section>
  );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Sidebar);