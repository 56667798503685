import { firebase } from '../firebase';
import { API_BASE_URL } from '../config';

import axios from 'axios';

export function getProjects() {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/projects`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      });
    })
}

export function getProject(id) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/projects`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        const projects = response.data.data;
        for (let i = 0; i < projects.length; i++) {
          if (projects[i].id === id) {
            return projects[i];
          }
        }
      }).catch((error) => {
        console.error(error);
      });
    })
}


export function updateProject(id, name, domain, headerAuthorization, minRetries, retryDelaySecs) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'put',
        url: `${API_BASE_URL}/projects/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          name: name,
          domain: domain,
          headerAuthorization: headerAuthorization,
          minRetries: Math.round(Number(minRetries)),
          retryDelaySecs: Math.round(Number(retryDelaySecs))
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            throw new Error(error.response.data.error);
          }
        } else {
          // Something else happened
          console.error('Error', error);
          throw new Error('An unexpected error occurred. Please try again.');
        }
      });
    })
}


export function createProject(name, domain) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'post',
        url: `${API_BASE_URL}/projects`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          name: name,
          domain: domain
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            throw new Error(error.response.data.error);
          }
        } else {
          // Something else happened
          console.error('Error', error);
          throw new Error('An unexpected error occurred. Please try again.');
        }
      });
    })
}

export function deleteProject(id) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'delete',
        url: `${API_BASE_URL}/projects/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).catch((error) => {
        console.error(error);
      });
    })
}