import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import withAuthorization from './withAuthorization';
import Sidebar from './Sidebar';

import * as api from '../api';

import * as routes from '../constants/routes';


const nameRe = /^[a-zA-Z0-9]+[a-zA-Z0-9-]+[a-zA-Z0-9]+$/;

const domainRe = /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,}[a-zA-Z0-9]){0,}\.)+[a-zA-Z0-9]+$/;

function NewProjectPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [serverError, setServerError] = useState('');

  const navigate = useNavigate();

  let nameError = '', domainError = '';

  if (name === '') {
    nameError = 'Please provide a name.'
  } else if (!nameRe.test(name)) {
    nameError = 'Please provide a valid name.'
  }

  if (domain === '') {
    domainError = 'Please provide a domain.'
  } else if (!domainRe.test(domain)) {
    domainError = 'Please provide a valid domain. Examples: example.com, sub-domain.example.com'
  }

  const isInvalid =
    nameError !== '' ||
    domainError !== '';

  const buttonClassName = isInvalid || isLoading ? "w-100 b ph3 pv2 input-reset ba b--light-blue bg-light-blue white f5 br1"
    : "w-100 b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1";

  const onSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    api.projects.createProject(name, domain).then(result => {
      navigate(`${routes.PROJECTS}/${result.id}`)
    }).catch(error => {
      setServerError(error.message);
      console.error(error);
    });
  }

  return (
    <div className="dt-ns w-100 center mw9">
      <Sidebar />
      <section className="pa4 dtc-ns center w-100 w-80-ns mw7">
        <article className="black mv4 ba b--black-70 br2 br--top bg-navy shadow-4 measure center" id="settings">
          <h2 className="tc f5 white mv0 pv2 ph3">Create New Project</h2>
          <div className="bg-near-white black pa3 bt b--black-70">
            <div className="dtc-ns">
              <form onSubmit={onSubmit}>
                <fieldset className="ba b--transparent ph0 mh0">
                  <legend className="ph0 mh0 fw6 clip">Project Settings</legend>
                  <div className="measure mb3">
                    <label className="b db f6 mb2" htmlFor="name">Name</label>
                    <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 measure br2" name="name" id="name"
                      value={name}
                      onChange={event => setName(event.target.value)}
                      type="text"
                      placeholder="project-name"
                    />
                    {nameError && <small id="name-error" className="f6 red db mb2">{nameError}</small>}
                    <small id="name-desc" className="f6 black-60 db mb2">Name for your project. Alphanumeric with dashes only.</small>
                  </div>
                  <div className="measure mb3">
                    <label className="b db f6 mb2" htmlFor="domain">Domain</label>
                    <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 measure br2" name="domain" id="domain"
                      value={domain}
                      onChange={event => setDomain(event.target.value)}
                      type="text"
                      placeholder="api.posthook.io"
                    />
                    {domainError && <small id="domain-error" className="f6 red db mb2">{domainError}</small>}
                    <small id="domain-desc" className="f6 black-60 db mb2">The domain where Posthook will make HTTPS requests to at the scheduled times.</small>
                  </div>
                </fieldset>
                {serverError &&
                  <div>
                    <p className="red lh-copy">{serverError}</p>
                    <br />
                  </div>
                }
                <button disabled={isInvalid} className={buttonClassName} type="submit">
                  Create Project
              </button>

              </form>
            </div>
          </div>
        </article>
      </section>
    </div>
  );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(NewProjectPage);