// CSS
import 'normalize.css';
import 'tachyons';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import './css/eui_theme_light.css';
import './css/custom.css';

import React from 'react';
import { BrowserRouter, Routes,
  Route, Navigate, useParams
} from 'react-router-dom';

import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import PasswordForgetPage from './components/PasswordForget';
import DashboardPage from './components/Dashboard';
import AccountPage from './components/Account';
import ProjectPage from './components/Project';
import ProjectsPage from './components/Projects';
import SequencePage from './components/Sequence';

import NewProjectPage from './components/NewProject';
import NewSequencePage from './components/NewSequence';
import ProjectHooksPage from './components/ProjectHooks';

import CheckoutPage from './components/Checkout';
import HooksPage from './components/Hooks';
import Header from './components/Header';
import Footer from './components/Footer';

import * as routes from './constants/routes';

import withAuthentication from './components/withAuthentication';

function App() {
  const { id } = useParams();

  return (
    <BrowserRouter basename="/app">
      <div className="avenir w-100 vh-100 flex flex-column">
        <Header />
        <main className="cf w-100 bt b--black-10 bg-washed-blue pb4" style={{ flex: 1 }}>
          <div className="">
            <div className="w-100">
              <Routes>
                <Route path={routes.SIGN_UP} element={<SignUpPage />} />
                <Route path={routes.SIGN_IN} element={<SignInPage />} />
                <Route path={routes.PASSWORD_FORGET} element={<PasswordForgetPage />} />
                <Route path={routes.DASHBOARD} element={<DashboardPage />} />
                <Route path={routes.ACCOUNT} element={<AccountPage />} />
                <Route path={routes.PROJECTS} element={<ProjectsPage />} />
                <Route path={routes.PROJECTS_NEW} element={<NewProjectPage />} />
                <Route path={routes.SEQUENCES + '/new'} element={<NewSequencePage />} />
                <Route path={routes.PROJECT + '/hooks'} element={<ProjectHooksPage />} />
                <Route path={routes.PROJECT} element={<ProjectPage key={id} />}/>
                <Route path={routes.SEQUENCE} element={<SequencePage />} />
                <Route path={routes.HOOKS} element={<HooksPage />} />
                <Route path={routes.CHECKOUT} element={<CheckoutPage />} />
                <Route path="*" element={<Navigate to={routes.DASHBOARD} />}/>
              </Routes>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default withAuthentication(App);