import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { auth } from '../firebase';
import * as routes from '../constants/routes';

const SignInPage = () =>
    <div className="w-100 ph3 mw6 pv3 center bg-white ba b--black-10 ma5">
        <h1 className="f3 fw5 tc">Sign In</h1>
        <p className="mt4">{"Don't have an account? "}<Link to={routes.SIGN_UP}>Sign up</Link>
        </p>
        <SignInForm />
    </div>

function SignInForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const navigate = useNavigate();

    function signInErrorMessage(error) {
        var errorMessage;
        switch (error.code) {
            case 'auth/invalid-email':
                errorMessage = "Email address is not valid.";
                break;
            case 'auth/user-disabled':
                errorMessage = "User is disabled.";
                break;
            case 'auth/user-not-found':
                errorMessage = "User not found.";
                break;
            case 'auth/wrong-password':
                errorMessage = "Password is incorrect.";
                break;
            default:
                errorMessage = error.message;
        }

        return errorMessage;
    }

    const onSubmit = (event) => {
        setIsLoading(true);

        auth.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                navigate(routes.DASHBOARD);
            })
            .catch(error => {
                setErrorMessage(signInErrorMessage(error));
                setIsLoading(false);
            });

        event.preventDefault();
    }

    const isInvalid =
        password === '' ||
        email === '';

    const buttonText = isLoading ? "Signing in..." : "Sign In"
    const buttonClassName = isLoading || isInvalid ? "w-100 b ph3 pv2 input-reset ba b--light-blue bg-light-blue white f5 br1" :
        "w-100 b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1";

    return (
        <div className="center">
        <article className="black-80">
        <form onSubmit={onSubmit}>
            <fieldset className="ba b--transparent ph0 mh0">
                <legend className="ph0 mh0 fw6 clip">Sign In</legend>
                <div className="mt3">
                    <label className="db fw5 lh-copy f5" htmlFor="email-address">Email</label>
                    <input className="pa2 input-reset ba bg-white w-100 br2" type="text" name="email" id="email"
                        value={email} onChange={event => setEmail(event.target.value)}/>
                </div>
                <div className="mt3">
                    <label className="db fw5 lh-copy f5" htmlFor="password">Password</label>
                    <input className="pa2 input-reset ba bg-white w-100 br2" type="password" name="password" id="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)} />
                </div>
            </fieldset>
            <div className="dib mt2 w-100">
                <button disabled={isInvalid} className={buttonClassName} type="submit">{buttonText}</button>
            </div>

            {errorMessage && <p className="red mt3">{errorMessage}</p>}
        </form>
        </article>
        <p className="tc mt3"><Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link></p>
        </div>
    );
}

export default SignInPage;

export {
    SignInForm,
};
