import { initializeApp } from "firebase/app"

import { getAuth } from "firebase/auth";

import { FIREBASE } from '../config';

const firebaseApp = initializeApp(FIREBASE);
const auth = getAuth(firebaseApp);

export {
    auth,
};