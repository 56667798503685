import React, { Component } from 'react';
import { status } from '../api';

const INITIAL_STATE = {
  color: "transparent",
};

class StatusLink extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    status.GetStatus().then(data => {
      if (data.down) {
        this.setState({ color: "red" });
      } else {
        this.setState({ color: "green" });
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  render() {
    const { color } = this.state;

    return (
      <a title="API Status" target="_blank" rel="noopener noreferrer" href="https://status.posthook.io" className="f6 dib ph2 link dark-blue dim">
        <span id="status-icon" className={color}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>
        </span> API Status
      </a>
    );
  }
}

export default StatusLink;