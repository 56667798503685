let apiHost;
let emailHostname;

const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'posthook.io') {
  apiHost = 'https://api.posthook.io';
  emailHostname = hostname;
} else if (hostname === 'dev.posthook.io') {
  apiHost = 'https://api.dev.posthook.io';
  emailHostname = hostname;
} else {
  apiHost = process.env.API_HOST || 'https://api.dev.posthook.io';
  emailHostname = 'dev.posthook.io';
}

export const EMAIL_CONTINUE_URL = `https://${emailHostname}/app/account`;

export const API_BASE_URL = `${apiHost}/${apiVersion}`;

export const API_HOST = apiHost;

export const PLANS = hostname === 'posthook.io' ? {
  project: {
    key: "project",
    name: "Project",
    price: "$19",
    priceCents: 1900,
    description: "50,000 scheduled requests",
    stripePlanID: "plan_Cnh69NyhZ0DYqZ"
  },
  startup: {
    key: "startup",
    name: "Startup",
    price: "$49",
    priceCents: 4900,
    description: "200,000 scheduled requests",
    stripePlanID: "plan_Cnh7rhZIIwTF4b"
  },
  corporate: {
    key: "corporate",
    name: "Corporate",
    price: "$129",
    priceCents: 12900,
    description: "1,000,000 scheduled requests",
    stripePlanID: "plan_Cnh7tj4JX09K0j"
  }
} : {
  project: {
    key: "project",
    name: "Project",
    price: "$19",
    priceCents: 1900,
    description: "50,000 scheduled requests",
    stripePlanID: "plan_Cn3OpOwyoGZsZQ"
  },
  startup: {
    key: "startup",
    name: "Startup",
    price: "$49",
    priceCents: 4900,
    description: "200,000 scheduled requests",
    stripePlanID: "plan_Cn3PahV7tJrSoH"
  },
  corporate: {
    key: "corporate",
    name: "Corporate",
    price: "$129",
    priceCents: 12900,
    description: "1,000,000 scheduled requests",
    stripePlanID: "plan_Cn3P5GTVy37OlQ"
  }
};

export const STRIPE = hostname === 'posthook.io' ?  {
  publishableKey: "pk_live_72EhvV31rkP0AEERdBvEqB0H",
  image: "https://posthook.io/apple-touch-icon.png"
} : {
  publishableKey: "pk_test_NkXt7u63UkkVXL870r28XmAq",
  image: "https://posthook.io/apple-touch-icon.png"
};

export const FIREBASE = hostname === 'posthook.io' ?
{
  apiKey: "AIzaSyDQ-zhQG085y6WAEh6Ff9m5kdkyQUbESEc",
  authDomain: "posthook.io"
}: {
  apiKey: 'AIzaSyChrsAJF5bt1vFHNlFhs47uwCb7JyEZzdg',
  authDomain: 'dev.posthook.io'
}