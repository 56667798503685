import React, { useEffect, useState } from 'react';

import AuthUserContext from './AuthUserContext';
import { firebase } from '../firebase';

const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
      firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          setAuthUser(authUser);
          window.$crisp.push(["set", "user:email", authUser.email]);
        } else {
          setAuthUser(null);
        }
      });
    }, []);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  }

  return WithAuthentication;
}

export default withAuthentication;