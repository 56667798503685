import React from 'react';
import { Link } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';
import SignOutButton from './SignOut';

import * as routes from '../constants/routes';

const Navigation = () =>
    <div className="db dtc-l v-mid w-100 w-third-l tc tr-l mt2 mt0-l">
    <AuthUserContext.Consumer>
        {authUser => authUser
            ? <NavigationAuth />
            : <NavigationNonAuth />
        }
    </AuthUserContext.Consumer>
    </div>

const NavigationAuth = () =>
    <nav>
        <NavigationElementExternal route="/pricing#faq" name="FAQ" />
        <NavigationElementExternal target="_blank" rel="noopener noreferrer" route="https://docs.posthook.io" name="Docs" />
        <SignOutButton />
    </nav>

const NavigationNonAuth = () =>
    <nav>
        <NavigationElementExternal target="_blank" rel="noopener noreferrer" route="https://docs.posthook.io" name="Docs" />
        <NavigationElementExternal route="/pricing" name="Pricing" />
        <NavigationElementExternal route="/pricing#faq" name="FAQ" />

        <NavigationElement route={routes.DASHBOARD} name="Dashboard" extraClasses="pa2 ml2 ba" />
    </nav>

const NavigationElement = (props) =>
    <Link to={props.route} className={"f6 fw6 hover-blue link black-70 mr2 mr3-m mr4-l dib " + props.extraClasses}>{props.name}</Link>

const NavigationElementExternal = (props) => 
    <a href={props.route} className="f6 fw6 hover-blue link black-70 mr2 mr3-m mr4-l dib">{props.name}</a>



export default Navigation;