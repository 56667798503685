import React, { useState, useEffect  } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import * as routes from '../constants/routes';
import AuthUserContext from './AuthUserContext';

import withAuthorization from './withAuthorization';
import { PLANS, STRIPE } from '../config';
import { PlanFromLocation } from '../helpers';

import * as api from '../api';
import { Check } from 'react-feather';

import poweredStripe from '../powered_by_stripe.svg'

function CheckoutPage() {
  const [plan, setPlan] = useState(PLANS.project);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangingPlan, setIsChangingPlan] = useState(false);

  const onGetStripeToken = (token) => {
    api.subscription.createSubscription(token, plan.stripePlanID).then(result => {
      navigate(routes.DASHBOARD);
    })
  }

  const stripeHandler = window.StripeCheckout.configure({
    key: STRIPE.publishableKey,
    image: STRIPE.image,
    locale: 'auto',
    token: onGetStripeToken
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let plan = PlanFromLocation(location);
    if (plan === '') {
      plan = PLANS.project;
    }
    setPlan(plan);

    api.subscription.getSubscription().then(subscription => {
      const activeSubscription = subscription && (subscription.status === 'active' || subscription.status === 'trialing');

      setIsChangingPlan(activeSubscription);
    }).catch(error => {
      console.error(error);
    });
  }, []);

  const onSelectPlan = (planKey) => {
    setPlan(PLANS[planKey]);
  }
  
  const onClickPay = (authUser, e) => {
    e.preventDefault()
    setIsLoading(true);

    const onCheckoutOpened = () => {
      setIsLoading(false);
    }

    // open Stripe Checkout
    stripeHandler.open({
      name: 'Posthook Subscription',
      description: `${plan.name} Plan (${plan.price} per month)`,
      amount: plan.priceCents, // 10 USD -> 1000 cents
      currency: 'USD',
      opened: onCheckoutOpened,
      email: authUser.email,
      panelLabel: 'Pay {{amount}} per month',
      allowRememberMe: false
    });
  }

  const buttonBaseText = isChangingPlan ? `Pay ${plan.price} per month` : `Pay ${plan.price} per month after the trial ends`;
  const buttonText = isLoading ? "Please wait ..." : buttonBaseText;
  const buttonClassName = isLoading ? "center tc b ph3 pv3 input-reset ba b--light-green bg-light-green white f4 br1" : "center tc b ph3 pv3 input-reset ba b--green bg-green white pointer f4 br1";
  const buttonSubText = isChangingPlan ? "Your plan change will take effect right away. You will be charged or credited a prorated amount." : "15 day trial, after which you will be charged the prorated amount for the rest of the calendar month and on every first of the month thereafter.";

  return (
    <AuthUserContext.Consumer>
    {authUser =>
      <div className="w-100 ph3 mw7 pv3 center">
        <h1 className="f3 fw5 tc">{isChangingPlan ? "Change your Plan" : "Confirm your Plan"}</h1>
          <PlanBox plan={PLANS.project} selected={plan.name === PLANS.project.name} onSelect={onSelectPlan}/>
          <PlanBox plan={PLANS.startup} selected={plan.name === PLANS.startup.name} onSelect={onSelectPlan}/>
          <PlanBox plan={PLANS.corporate} selected={plan.name === PLANS.corporate.name} onSelect={onSelectPlan} />
          <div className="fl center tc w-100">
            <div>
              <button className={buttonClassName} onClick={(e) => onClickPay(authUser, e)}>{buttonText}</button>
              <p className="lh-copy tc black-80 mt2 mw6 center f5">{buttonSubText}</p>
              <a className="cf" href="https://stripe.com"><img src={poweredStripe} className="mt2" alt="Powered by Stripe"></img></a>
            </div>
          </div>
      </div>
    }
    </AuthUserContext.Consumer>
  );
}

const PlanBox = ({ plan, selected, onSelect }) =>
  <div className="fl w-100 w-third-l pa2-l mb4">
    <div className="tc w-100 bg-white ba b--black-10 pv3 shadow-4">
      <h3 className="f3 fw4">{ plan.name}</h3>
      <p className="f4 db lh-copy tc black-70"><span className="f2 black-70">{plan.price}</span>/month</p>
      <p className="f5 db lh-copy tc black-70">{plan.description}</p>
      { selected ?
        <button disabled={true} className="b ph3 pv2 input-reset ba b--blue bg-white black pointer f5 br1">< Check height="16" className="v-mid" /> Selected</button> :
        <button className="b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1" onClick={(e) => onSelect(plan.key, e)}>Change</button>
      }
    </div>
  </div>



const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(CheckoutPage);