import React, { Component } from 'react';

import { auth } from '../firebase';

const INITIAL_STATE = {
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
    error: null,
};

class PasswordChangeForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (event) => {
        const { password, newPassword } = this.state;

        auth.doReauthenticateWithPassword(password)
            .then(() => {
                auth.doPasswordUpdate(newPassword)
                    .then(() => {
                        this.props.onSuccess();
                        this.setState({ ...INITIAL_STATE });
                    })
                    .catch(error => {
                        this.setState({ 'error': error });
                    })
            })
            .catch(error => {
                if (error.code === "auth/wrong-password") {
                    error.message = "The current password is incorrect. Please try again."
                }
                this.setState({ 'error': error });
            })

        event.preventDefault();
    }

    render() {
        const {
            password,
            newPassword,
            newPasswordConfirm,
            error,
        } = this.state;

        const isInvalid =
            password === '' ||
            newPassword !== newPasswordConfirm ||
            newPassword === '';

        const buttonClassName = isInvalid ? "w-100 b ph3 pv2 input-reset ba b--light-blue bg-light-blue white f5 br1"
            : "w-100 b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1";
        return (
            <form onSubmit={this.onSubmit}>
                <fieldset className="ba b--transparent ph0 mh0">
                    <legend className="ph0 mh0 fw6 clip">Change Password</legend>
                    <div className="">
                        <label className="db fw5 lh-copy f5" htmlFor="current-password">Current Password</label>
                        <input className="pa2 input-reset ba bg-white w-100 measure br2" name="current-password" id="current-password"
                            value={password}
                            onChange={event => this.setState({ 'password': event.target.value })}
                            type="password"
                            placeholder="Current Password"
                        />
                    </div>
                    <div className="mt3">
                        <label className="db fw5 lh-copy f5" htmlFor="new-password">New Password</label>
                        <input className="pa2 input-reset ba bg-white w-100 measure br2" name="new-password" id="new-password"
                            value={newPassword}
                            onChange={event => this.setState({ 'newPassword': event.target.value })}
                            type="password"
                            placeholder="New Password"
                        />
                    </div>
                    <div className="mt3">
                        <label className="db fw5 lh-copy f5" htmlFor="new-password-confirm">Confirm New Password</label>
                        <input className="pa2 input-reset ba bg-white w-100 measure br2" name="new-password-confirm" id="new-password-confirm"
                            value={newPasswordConfirm}
                            onChange={event => this.setState({ 'newPasswordConfirm': event.target.value })}
                            type="password"
                            placeholder="Confirm New Password"
                        />
                    </div>
                </fieldset>
                <button disabled={isInvalid} className={buttonClassName} type="submit">
                    Change Password
                </button>

                {error && <p className="red mt3">{error.message}</p>}
            </form>
        );
    }
}

export default PasswordChangeForm;