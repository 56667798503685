import React, { Component } from 'react';

import yaml from 'js-yaml';

import { useNavigate } from 'react-router-dom';

import { DateTimePicker } from "@blueprintjs/datetime";
import { Classes, HTMLSelect, Button, Icon, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { sequences } from '../api';


const INITIAL_STATE = {
  id: '',
  projectID: '',
  name: '',
  startAtDate: new Date(),
  startAtDateFocused: false,
  repeatUnit: 'minutes',
  repeatCount: 30,
  steps: '',
  stepsYaml: '',
  isLoading: true,
  serverError: '',
  stepsError: '',
  showStartAtCalendar: false,
  isMoreOpen: false,
}

const nameRe = /^[a-zA-Z0-9]+[a-zA-Z0-9-]+[a-zA-Z0-9]+$/;

class SequenceForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if (!this.props.isNew) {
      sequences.getSequence(this.props.sequenceID).then(sequence => {
        const stepsYaml = yaml.dump(sequence.steps, {indent: 4});
        this.setState({
          id: sequence.id,
          projectID: sequence.projectID,
          name: sequence.name,
          startAtDate: new Date(sequence.startAt),
          repeatUnit: sequence.repeatInterval.unit,
          repeatCount: sequence.repeatInterval.count,
          steps: sequence.steps,
          stepsYaml: stepsYaml,
          stepsError: '',
          isLoading: false
        });
      }).catch(error => {
        this.setState({ serverError: error.message, isLoading: false });
      })
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  onReset = (event) => {
    event.preventDefault()
    this.setState(INITIAL_STATE);

    this.componentDidMount();
  }

  onDeleteSequence = () => {  
    sequences.deleteSequence(this.props.sequenceID).then(() => {
      this.props.navigate(`/projects/${this.state.projectID}#sequences`);
    }).catch(error => {
      this.setState({ serverError: error.message, isLoading: false });
    })
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    let steps = {};

    if (this.state.stepsYaml === '') {
      this.setState({
        stepsError: 'Please provide steps.',
        isLoading: false
      })
      return;
    }
  
    try {
      steps = yaml.load(this.state.stepsYaml);
    } catch (error) {
      if (error.message === "Unable to parse.") {
        const errorMessage = `Unable to parse YAML. Error at line ${error.parsedLine}, "${error.snippet}"`;
        this.setState({
          stepsError: errorMessage,
          isLoading: false
        });
        return;
      } else {
        throw error;
      }
    }

    if (typeof steps !== 'object' || Array.isArray(steps)) {
      this.setState({
        stepsError: 'Steps not in the correct format. See example.',
        isLoading: false
      });
      return;
    }

    if (this.props.isNew) {
      sequences.createSequence(this.props.projectID, this.state.name, this.state.startAtDate, this.state.repeatUnit, this.state.repeatCount, steps).then(result => {
        this.props.navigate(`/sequences/${result.id}`);
      }).catch(error => {
        this.setState({ serverError: error.message, isLoading: false });
      })
    } else {
      sequences.updateSequence(this.state.id, this.state.name, this.state.startAtDate, this.state.repeatUnit, this.state.repeatCount, steps).then(sequence => {
        const stepsYaml = yaml.dump(sequence.steps, {indent: 4});
        this.setState({
          id: sequence.id,
          name: sequence.name,
          startAtDate: new Date(sequence.startAt),
          repeatUnit: sequence.repeatInterval.unit,
          repeatCount: sequence.repeatInterval.count,
          steps: sequence.steps,
          stepsYaml: stepsYaml,
          isLoading: false,
          stepsError: '',
          serverError: ''
        });
      }).catch(error => {
        this.setState({ serverError: error.message, isLoading: false });
      })
    }
  }

  render() {
    let nameError = '';

    if (this.state.name === '') {
      nameError = 'Please provide a name.'
    } else if (!nameRe.test(this.state.name)) {
      nameError = 'Please provide a valid name.'
    }

    let startAtError = '';
    let repeatCountOptions = [];

    switch (this.state.repeatUnit) {
      case 'minutes':
        repeatCountOptions = [5, 10, 15, 20, 30, 60, 90];
        break;
      case 'hours':
        repeatCountOptions = [1, 2, 3, 4, 5, 6, 12];
        break;
      case 'days':
        repeatCountOptions = [1, 2, 3, 5, 7, 14, 21, 28];
        break;
      case 'months':
        repeatCountOptions = [1, 2, 3, 6, 12];
        break;
    }
    // if (this.state.repeatCount === 0) {
    //   this.setState({ 'repeatCount': repeatCountOptions[0] });
    // }

    const isInvalid =
      nameError !== '';
    const saveButtonClassName = isInvalid || this.state.isLoading ? "b ma2 ph3 pv2 input-reset ba b--light-blue bg-light-blue white f5 br1"
      : "b ma2 ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1";
    
    const saveButtonText = this.props.isNew ? "Create Sequence" : "Update Sequence";
    const resetButtonText = "Reset";
    const resetButtonClassName = "b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-white black-60 pointer f5 br1"

    return (
      <form onSubmit={this.onSubmit}>
        <fieldset className="ba b--transparent ph0 mh0">
          <legend className="ph0 mh0 fw6 clip">Sequence Settings</legend>
          <div>
            <label htmlFor="name"><h3 className="b db f5 mb2">Name</h3></label>
            <div className="mb3 flex flex-wrap">
              <div className="w-40-ns w-100">
              <label htmlFor="name"><small id="name-desc" className="f6 black-60 db mb2 mr3">Name to help you identify the Sequence.. Alphanumeric with dashes only.</small></label>
              </div>
              <div className="w-60-ns w-100">
                <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 br2 measure" name="name" id="name"
                  value={this.state.name}
                  onChange={event => this.setState({ 'name': event.target.value })}
                  type="text"
                  placeholder="sequence-name"
                />
                {nameError && <small id="name-error" className="f6 red db mb2">{nameError}</small>}
              </div>
            </div>
          </div>
          <hr/>
          <div>
            <label htmlFor="start-at"><h3 className="b db f5 mb2">Start At</h3></label>
            <div className="mb3 flex flex-wrap">
              <div className="w-40-ns w-100">
              <label htmlFor="start-at"><small id="start-at-desc" className="f6 black-60 db mb2 mr3">Date and time when the Sequence will first run. It is selected in local time but stored in UTC.</small></label>
              </div>
              <div className="w-60-ns w-100">
                <div className="measure pb2">
                  <Button
                    large={false}
                    // key="startAtTag"
                    icon="calendar"
                    // interactive={true}
                    onClick={() => this.setState({"showStartAtCalendar": !this.state.showStartAtCalendar})}
                  >
                    {this.state.startAtDate.toISOString()}
                  </Button>
                  { this.state.showStartAtCalendar &&
                    <div className="mt2">
                      <DateTimePicker value={this.state.startAtDate} onChange={(selectedDate) => this.setState({ 'startAtDate': selectedDate })}
                        className={Classes.ELEVATION_1}
                        canClearSelection={false}
                        timePickerProps={{ useAmPm: true }}
                      />
                    </div>
                  }
                </div>
                {startAtError && <small id="start-at-error" className="f6 red db mb2 mr3">{startAtError}</small>}
              </div>
            </div>
          </div>
          <hr />
          <div className="mb3">
            <label htmlFor="repeat-interval"><h3 className="b db f5 mb2">Repeat Interval</h3></label>
            <div className="flex flex-wrap">
              <div className="w-40-ns w-100">
                <label htmlFor="repeat-interval"><small id="repeat-interval-desc" className="f6 black-60 db mb2">The time interval between each run of the Sequence.</small></label>
              </div>
              <div className="w-60-ns w-100">
                <div className="pb2">
                  <HTMLSelect
                    onChange={event => this.setState({ 'repeatCount': event.target.value })}
                    value={this.state.repeatCount}
                    options={repeatCountOptions}
                    large={true}
                  />
                  <HTMLSelect
                    onChange={event => this.setState({ 'repeatUnit': event.target.value, 'repeatCount': 0})}
                    value={this.state.repeatUnit}
                    options={["minutes", "hours", "days", "months"]}
                    large={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="">
            <label htmlFor="steps"><h3 className="b db f5 mb2">Steps</h3></label>
            <div className="flex flex-wrap">
              <div className="w-40-ns w-100">
                <small id="steps-desc" className="f6 black-60 db mb2 mr3">YAML definition of your sequence. <br/>Example:</small>
                <div className="br2 b--black-70 white bg-dark-gray w-90">
                  <pre className="pre pa3"><code>{`getNewCatImages:
  path: /ph/getNewCats
getNewDogImages:
  path: /ph/getNewDogs
publish:
  path: /ph/publish
  requires:
    - getNewDogImages
    - getNewCatImages
`}</code></pre>
                </div>
              </div>
              <div className="w-60-ns w-100">
                <textarea rows="10" cols="100" className="w-100 pa2 mb2 input-reset ba b--black-20 bg-white w-100 br2 code" name="steps" id="steps"
                  value={this.state.stepsYaml}
                  type="text"
                  placeholder=""
                  onChange={(e) => this.setState({ "stepsYaml": e.target.value })}
                />
                {this.state.stepsError && <small id="steps-error" className="f6 red db mb2">{this.state.stepsError}</small>}
              </div>
          </div>
        </div>
        </fieldset>
        {this.state.serverError &&
          <div>
            <p className="red lh-copy">{this.state.serverError}</p>
            <br />
          </div>
        }
        <div className="w-100 w-75-ns center tc">
          <button disabled={isInvalid} className={saveButtonClassName} type="submit">
            { saveButtonText}
          </button>
          <button className={resetButtonClassName} onClick={(e) => this.onReset(e)} type="reset">
            {resetButtonText}
          </button>
          { !this.props.isNew &&
            <Popover
              interactionKind={PopoverInteractionKind.CLICK}
              popoverClassName="bp3-popover-content-sizing"
              position={Position.BOTTOM}
            >
              <button type="button" className="b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-light-red black-60 pointer f5 br1"><Icon icon={IconNames.TRASH} /></button>
              <div>
                <h5>Confirm Deletion</h5>
                <p>Are you sure you want to delete this sequence?</p>
                <button className="b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-white black-60 pointer f5 br1 bp3-popover-dismiss">
                  Cancel
                </button>
                <button className="b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-red white pointer f5 br1 " onClick={() => this.onDeleteSequence()}>
                  Delete
                </button>
              </div>
            </Popover>
          }
        </div>
      </form>
    )
  }
}

export default function(props) {
  const navigate = useNavigate();

  return <SequenceForm {...props} navigate={navigate} />;
}
