export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ROOT = '/';
export const DASHBOARD = '/dashboard';
export const ACCOUNT = '/account';
export const ACCOUNT_API_KEYS = '/account#api_keys';
export const ACCOUNT_SIGNING_KEY = '/account#signing_key'
export const ACCOUNT_NOTIFICATIONS = '/account#notifications';
export const PROJECT = '/projects/:id';
export const PROJECTS_NEW = '/projects/new';
export const PROJECTS = '/projects';
export const PROJECTS_LIST = '/account#projects';
export const SEQUENCES = '/projects/:project_id/sequences';
export const SEQUENCE = '/sequences/:id';
export const HOOKS = '/hooks';
export const HOOKS_PENDING = '/hooks#pending';
export const HOOKS_FAILED = '/hooks#failed';
export const HOOKS_COMPLETED = '/hooks#completed';
export const PASSWORD_FORGET = '/forgot-password';
export const CHECKOUT = '/checkout';

export const MANIFOLD_SSO = '/msso/v1/sso';
