import { firebase } from '../firebase';
import { API_BASE_URL } from '../config';

import axios from 'axios';

export function getHookMetrics() {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/metrics/hooks`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      });
    })
}

export function getHooks({projectID, status, limit, offset, sortOrder, postAtBefore, postAtAfter}) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      const params = {
        projectID: projectID,
        status: status,
        limit: limit,
        offset: offset,
        sortBy: 'postAt',
        sortOrder: sortOrder.toUpperCase(),
        postAtBefore: postAtBefore,
        postAtAfter: postAtAfter
      };

      return axios({
        method: 'get',
        url: `${API_BASE_URL}/hooks`,
        params: params,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return {
          data: response.data.data,
          meta: response.data.meta
        };
      }).catch((error) => {
        console.error(error);
      });
    })
}

export function retryHooks({ projectID, hookIDs }) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'post',
        url: `${API_BASE_URL}/hooks/retryIDs`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          projectID,
          hookIDs
        }
      }).then(() => {
        return {};
      }).catch((error) => {
        console.error(error);
      });
    })
}