import { firebase } from '../firebase';
import { API_BASE_URL } from '../config';

import axios from 'axios';

export function getSequences(projectID) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/projects/${projectID}/sequences`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      });
    });
}

export function getSequence(id) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/sequences/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      });
    });
}

export function deleteSequence(id) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'delete',
        url: `${API_BASE_URL}/sequences/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status !== 200 || error.response.status !== 404) {
            throw new Error(error.response.data.error);
          }
        } else {
          // Something else happened
          console.error('Error', error);
          throw new Error('An unexpected error occurred. Please try again.');
        }
      });
    });
}

export function createSequence(projectID, name, startAtDate, repeatUnit, repeatCount, steps) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'post',
        url: `${API_BASE_URL}/projects/${projectID}/sequences`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          name: name,
          startAt: startAtDate.toISOString(),
          repeatInterval: {
            unit: repeatUnit,
            count: Number(repeatCount),
          },
          steps: steps
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            throw new Error(error.response.data.error);
          }
        } else {
          // Something else happened
          console.error('Error', error);
          throw new Error('An unexpected error occurred. Please try again.');
        }
      });
    });
}

export function updateSequence(id, name, startAtDate, repeatUnit, repeatCount, steps) {
  console.log(repeatCount)
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'put',
        url: `${API_BASE_URL}/sequences/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          name: name,
          startAt: startAtDate.toISOString(),
          repeatInterval: {
            unit: repeatUnit,
            count: Number(repeatCount),
          },
          steps: steps
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            throw new Error(error.response.data.error);
          }
        } else {
          // Something else happened
          console.error('Error', error);
          throw new Error('An unexpected error occurred. Please try again.');
        }
      });
    });
}