import { firebase } from '../firebase';
import { API_BASE_URL } from '../config';

import axios from 'axios';

export function createSubscription(stripeToken, planID) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'post',
        url: `${API_BASE_URL}/subscription`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          stripeToken: stripeToken,
          planID: planID
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      })
    })
}

export function cancelSubscription(stripeToken, planID) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'delete',
        url: `${API_BASE_URL}/subscription`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      })
    })
}

export function getSubscription() {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/subscription`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      })
    })
}
