import { Component } from 'react';

import withAuthorization from './withAuthorization';
import { useNavigate } from 'react-router-dom';
import { projects } from '../api';


class HooksPage extends Component {  
  componentDidMount() {
    let navigate = useNavigate();

    projects.getProjects().then(results => {
      navigate(`/projects/${results[0].id}/hooks`);
    });
  }
  render() {
    return null;
  }
}

const authCondition = (authUser) => !!authUser;


export default withAuthorization(authCondition)(HooksPage);