import * as queryString from 'query-string';

import { PLANS } from '../config';

export const PlanFromLocation = (location) => {
  const parsed = queryString.parse(location.search);
  const plan = PLANS[parsed.plan];
  if (plan) {
    return plan
  }

  return ''
}

export const QueryValueFromLocation = (location, key) => {
  const parsed = queryString.parse(location.search);

  return parsed[key];
}

export const precisionRound = (number, precision) => {
  var factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export const SecsToDisplayStr = (secs) => {
  if (secs < 1) {
    return `${Math.round(secs * 1000)} ms`
  } else {
    return `${precisionRound(secs, 2)} s`
  }
}