import axios from 'axios';

export function GetStatus() {
  return axios({
    method: 'get',
    url: `https://updown.io/api/checks/fj8q`,
    headers: {
      'X-API-Key': 'ro-JcqCjGhR9LsA3ZSzt6b7'
    }
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    console.error(error);
  });
}
