import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import withAuthorization from './withAuthorization';
import Sidebar from './Sidebar';
import * as routes from '../constants/routes';

import * as api from '../api';
import { Link, useParams } from 'react-router-dom';

import { Icon, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";

import { IconNames } from "@blueprintjs/icons";


import { HashLink } from "react-router-hash-link";

import { Copy } from 'react-feather';

const emailRe = /.+@.+\..+/;

const INITIAL_PROJECT_STATE = {
  id: '', 
  name: '',
  domain: '',
  headerAuthorization: '',
  minRetries: 1,
  retryDelaySecs: 5
};

const INITIAL_SEQUENCES = [{path: "/test"}, {path: "/test2"}];

// const INITIAL_STATE = {
//     notificationSettings: [
//       // {
//       //     kind: 'email',
//       //     identifier: '',
//       //     status: 'active',
//       //     data: {},
//       // }
//     ],
// }

const nameRe = /^[a-zA-Z0-9]+[a-zA-Z0-9-]+[a-zA-Z0-9]+$/;

const domainRe = /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,}[a-zA-Z0-9]){0,}\.)+[a-zA-Z0-9]+$/;

function ProjectPage(){
  const { id } = useParams();
  const [project, setProject] = useState(INITIAL_PROJECT_STATE);
  const [apiKey, setApiKey] = useState('');
  const [signingKey, setSigningKey] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [serverError, setServerError] = useState('');
  const [sequences, setSequences] = useState(INITIAL_SEQUENCES);
  const [newNotificationEmail, setNewNotificationEmail] = useState('');
  const [notificationEmailError, setNotificationEmailError] = useState('');
  const [notificationSettings, setNotificationSettings] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    api.sequences.getSequences(id).then(result => {
      setSequences(result);
    }).catch(error => {
      console.error(error);
    });

    api.notifications.getNotifications(id).then(result => {
      setNotificationSettings(result);
    }).catch(error => {
      console.error(error);
    });

    api.projects.getProject(id).then(project => {
      setProject(project);

      api.tokens.getTokens().then(tokens => {
        const apiKeys = tokens.apiKeys;
        for (let i = 0; i < apiKeys.length; i++) {
          if (apiKeys[i].id === project.apiKeyID) {
            setIsLoading(false);
            setApiKey(apiKeys[i].token);
            setSigningKey(tokens.signingKey);
          }
        }
      });
    }).catch(error => {
      console.error(error);
    });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const {
      id,
      name,
      domain,
      headerAuthorization,
      minRetries,
      retryDelaySecs
    } = project;

    api.projects.updateProject(id, name, domain, headerAuthorization, minRetries, retryDelaySecs).then(result => {
      setProject(result);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }).catch(error => {
      setServerError(error.message);
      console.error(error);
    });
  }
  
  const handleFocusSelect = (event) => {
    event.target.select();
  }

  const onCreateNotificationEmail = () => {
    const email = newNotificationEmail;
    if (email === '') {
      setNotificationEmailError('Please provide an email address.');
      return
    }
    if (!emailRe.test(email)) {
      setNotificationEmailError('Please provide a valid email address.');
      return
    }

    api.notifications.addNotificationEmail(id, email).then(() => {
      return api.notifications.getNotifications(id).then(result => {
        setNotificationSettings(result);
        setNewNotificationEmail('');
        setNotificationEmailError('');
      }).catch(error => {
        console.error(error);
      });
    }).catch(error => {
      console.error(error);
    })
  }

  const onDeleteNotificationChannel = (e, kind, identifier) => {
    const projectID = id;
    api.notifications.deleteNotificationChannel(projectID, kind, identifier).then(result => {
      return api.notifications.getNotifications(projectID).then(result => {
        setNotificationSettings(result);
      }).catch(error => {
        console.error(error);
      });
    }).catch(error => {
      console.error(error);
    });
  }

  const onDeleteProject = () => {
    const projectID = id;
    api.projects.deleteProject(projectID).then(result => {
      navigate(routes.PROJECTS);
    }).catch(error => {
      console.error(error);
    });
  }

  let nameError = '';
  if (project.name === '') {
    nameError =  'Please provide a name.';
  } else if (!nameRe.test(project.name)) {
    nameError = 'Please provide a valid name.';
  }

  let domainError = ''
  if (project.domain === '') {
    domainError = 'Please provide a domain.';
  } else if (!domainRe.test(project.domain)) {
    domainError = 'Please provide a valid domain. Examples: example.com, sub-domain.example.com';
  }


  let retriesError = '';
  const minRetriesNum = Number(project.minRetries);

  if (project.minRetries === '') {
    retriesError = 'Please provide the number of retries.';
  } else if (Number.isNaN(project.minRetriesNum)) {
    retriesError = 'Please provide a number.';
  } else if (minRetriesNum < 1 || minRetriesNum > 10) {
    retriesError = 'Retries can not be less than 1 or more than 10';
  }

  let retryDelayError = '';
  const retryDelayNum = Number(project.retryDelaySecs);
  if (project.retryDelaySecs === '') {
    retryDelayError = 'Please provide the number for the delay between retries.';
  } else if (Number.isNaN(retryDelayNum)) {
    retryDelayError = 'Please provide a number.';
  } else if (retryDelayNum < 5 || retryDelayNum > 60) {
    retryDelayError = 'Retry Delay can not be less than 5 or more than 60.';
  }

  const headerAuthorizationError = '';

  const isInvalid = nameError !== '' ||
    domainError !== '' ||
    headerAuthorizationError !== '' ||
    retriesError !== '' ||
    retryDelayError !== '';

  const buttonClassName = isInvalid || isLoading ? "w-80 b ph3 pv2 input-reset ba b--light-blue bg-light-blue white f5 br1" : 
    "w-80 b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1";

  const onProjectChange = e => {
    setProject({
      ...project,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div className="dt-ns w-100 center mw9">
      <Sidebar/>
      <section className="pa4 dtc-ns center w-100 w-80-ns mw7">
        <article className="black mv4 ba b--black-70 br2 br--top bg-navy shadow-4" id="settings">
          <h2 className="tc f5 white mv0 pv2 ph3">Project Settings</h2>
          <div className="bg-near-white black pa3 bt b--black-70">
          <div className="dtc-ns">
            <form onSubmit={onSubmit}>
              <fieldset className="ba b--transparent ph0 mh0">
                <legend className="ph0 mh0 fw6 clip">Project Settings</legend>
                <div className="measure mb3">                    
                  <label className="b db f6 mb2" htmlFor="name">Name</label>
                  <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 measure br2"
                    id="name"
                    name="name"
                    value={project.name}
                    onChange={onProjectChange}
                    type="text"
                  />
                  {nameError && <small id="name-error" className="f6 red db mb2">{nameError}</small>}
                  <small id="name-desc" className="f6 black-60 db mb2">Name for your project. Alphanumeric with dashes only.</small>
                </div>
                <div className="measure mb3">
                  <label className="b db f6 mb2" htmlFor="domain">Domain</label>
                  <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 measure br2"
                    id="domain"
                    name="domain"
                    value={project.domain}
                    onChange={onProjectChange}
                    type="text"
                  />
                    {domainError && <small id="domain-error" className="f6 red db mb2">{domainError}</small>}
                  <small id="domain-desc" className="f6 black-60 db mb2">The domain where Posthook will make HTTPS requests to at the scheduled times.</small>
                </div>
                <div className="measure mb3">
                  <label className="b db f6 mb2" htmlFor="minRetries">Retries</label>
                  <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 measure br2"
                    id="minRetries"
                    name="minRetries"
                    value={project.minRetries}
                    onChange={onProjectChange}
                    type="text"
                    placeholder=""
                  />
                    {retriesError && <small id="retries-error" className="f6 red db mb2">{retriesError}</small>}
                  <small id="retries-desc" className="f6 black-60 db mb2">Number of times Posthook will retry hooks (1-10).</small>
                </div>
                <div className="measure mb3">
                  <label className="b db f6 mb2" htmlFor="retryDelaySecs">Retry Delay (seconds)</label>
                  <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 measure br2"
                    id="retryDelaySecs"
                    name="retryDelaySecs"
                    value={project.retryDelaySecs}
                    onChange={onProjectChange}
                    type="text"
                    placeholder=""
                  />
                    {retryDelayError && <small id="retry-delay-error" className="f6 red db mb2">{retryDelayError}</small>}
                  <small id="retry-delay-desc" className="f6 black-60 db mb2">Number of seconds Posthook will wait between retries (5-60).</small>
                </div>
                <div className="measure mb3">
                  <label className="b db f6 mb2" htmlFor="headerAuthorization">Custom Authorization Header <small>(optional)</small></label>
                  <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 measure br2"
                    id="headerAuthorization"
                    name="headerAuthorization"
                    value={project.headerAuthorization}
                    onChange={onProjectChange}
                    type="text"
                  />
                  {headerAuthorizationError && <small id="header-authorization-error" className="f6 red db mb2">{headerAuthorizationError}</small>}
                  <small id="header-authorization--desc" className="f6 black-60 db mb2">Specify a custom value for the Authorization header Posthook will use when calling your API.</small>
                </div>
              </fieldset>
              {serverError &&
                <div>
                  <p className="red lh-copy">{serverError}</p>
                  <br />
                </div>
              }
              <div className="tc center">
                <button disabled={isInvalid} className={buttonClassName} type="submit">
                  Save Changes
                </button>
                <Popover
                  interactionKind={PopoverInteractionKind.CLICK}
                  popoverClassName="bp3-popover-content-sizing"
                  position={Position.TOP}
                >
                  <button title="Delete Project" type="button" className="b ma2 ph3 pv2 input-reset ba b--black-40 bg-light-red black-60 pointer f5 br1"><Icon icon={IconNames.TRASH} /></button>
                  <div>
                    <h5>Confirm Deletion</h5>
                    <p>{"Are you sure you want to delete this project? All of its hooks and sequences will also be deleted immediately."}</p>
                    <button className="b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-white black-60 pointer f5 br1 bp3-popover-dismiss">
                      Cancel
                    </button>
                    <button className="b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-red white pointer f5 br1 " onClick={onDeleteProject}>
                      Delete
                    </button>
                  </div>
                </Popover>
              </div>

            </form>
            </div>
            <div className="dtc-ns pl5-ns mt3">
              <div className="ba pa3 b--black-20">
                <div className="measure mb3">
                  <label className="b db f5 mb2" htmlFor="api-key">API Key < Copy height="14" width="14" className="ml1" /></label>
                  <input className="pa2 mb2 input-reset ba b--black-20 bg-white w-100 measure br2" name="api-key" id="api-key"
                    value={apiKey}
                    type="text"
                    placeholder=""
                    readOnly
                    onFocus={handleFocusSelect}
                  />
                  <small id="api-key-desc" className="f6 black-60 db mb2">Use this key to schedule and manage hooks for this project via the Posthook API.</small>
                </div>
                <div>
                  <label className="b db f5 mb2" htmlFor="signing-key">Signing Key < Copy height="14" width="14" className="ml1" /></label>
                  <textarea rows="3" className="measure pa2 mb2 input-reset ba b--black-20 bg-white w-100 br2" name="signing-key" id="signing-key"
                    value={signingKey}
                    type="text"
                    placeholder=""
                    readOnly
                    onFocus={handleFocusSelect}
                  />
                  <small id="signing-key-desc" className="f6 black-60 db mb2">Used to validate that requests are being made from Posthook.</small>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="black-80 center mv4 ba b--black-70 br2 br--top bg-navy shadow-4" id="notifications">
          <h2 className="tc f5 white mv0 pv2 ph3">Notification Emails</h2>
          <div className="pv4 bg-near-white black pa3 bt b--black-70">
            <div className="overflow-auto w-100">
              <h3 className="f5 mb4">These email addresses are notified when Posthook is having trouble fulfilling Hooks on your API.</h3>
              <table className="f6 w-100" cellSpacing="0">
                <tbody className="lh-copy">
                  {
                    notificationSettings.map((setting) =>
                        <tr key={setting.identifier}>
                          <td className="pv2 pr3 tc">{setting.identifier}</td>
                          <td className="pv2 pr3 tc">
                            <Popover
                              interactionKind={PopoverInteractionKind.CLICK}
                              popoverClassName="bp3-popover-content-sizing"
                              position={Position.BOTTOM}
                            >
                              <button type="button" className="b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-light-red black-60 pointer f5 br1 tl"><Icon icon={IconNames.TRASH} /></button>
                              <div>
                                <h5>Confirm Deletion</h5>
                                <p>Are you sure you want to delete this notification email?</p>
                                <button className="b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-white black-60 pointer f5 br1 bp3-popover-dismiss">
                                  Cancel
                                </button>
                                <button className="b ma2 ph3 pv2 input-reset ba b--black-40 dim bg-red white pointer f5 br1" onClick={(e) => onDeleteNotificationChannel(e, setting.kind, setting.identifier)}>
                                  Delete
                                </button>
                              </div>
                            </Popover>
                            {/* <button onClick={(e) => this.onDeleteNotificationChannel(e, setting.kind, setting.identifier)} className="dtc-ns b ph2 pv1 input-reset ba b--light-red bg-light-red white pointer f5 br1 center">< Trash2 height="16" className="v-mid" /></button> */}
                          </td>
                        </tr>
                    )
                  }
                  <tr key="createNew">
                    <td className="pv3 pr3 bb b--black-20 tc">
                      <input className="pa2 input-reset ba bg-white w-100 measure br2 tc"
                        value={newNotificationEmail}
                        onChange={event => setNewNotificationEmail(event.target.value)}
                        name="newNotificationEmail" id="newNotificationEmail"
                        placeholder="support@posthook.io"
                      ></input>
                    </td>
                    <td className="pv3 pr3 bb b--black-20">
                      <button onClick={(e) => onCreateNotificationEmail(e)} className="dtc-ns b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1 tc center">Add Notification Email</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {notificationEmailError &&
                <p className="red lh-copy tc">{notificationEmailError}</p>
              }
            </div>
          </div>
        </article>
        <article className="black mv4 ba b--black-70 br2 br--top bg-navy shadow-4" id="sequences">
          <h2 className="tc f5 white mv0 pv2 ph3">Sequences</h2>
          <div className="bg-near-white black pa3 bt b--black-70">
            <h3 className="f5 mb4">Sequences allow you to chain requests and repeat them on an interval. Perfect for those tasks that need to run every so often. For more details, check out the <a href="https://docs.posthook.io/#sequences">docs</a>.</h3>
            <div className="overflow-auto w-100">
              <table className="f6 w-100" cellSpacing="0">
                <thead>
                  <tr>
                    <th className="fw6 bb b--black-20 tc pb3 pr3">Name</th>
                    <th className="fw6 bb b--black-20 tc pb3 pr3">Repeat Interval</th>
                  </tr>
                </thead>
                <tbody className="lh-copy">
                  {
                    sequences.map((sequence) => 
                      <tr key={sequence.id}>
                        <td className="pv3 pr3 bb b--black-20 tc"><Link to={`/sequences/${sequence.id}`} className="blue link">{sequence.name}</Link></td>
                        <td className="pv3 pr3 bb b--black-20 tc">{sequence.repeatInterval && `${sequence.repeatInterval.count} ${sequence.repeatInterval.unit}`}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            <HashLink to={`/projects/${id}/sequences/new#settings`} className=""><button className="db mt3 center b ph3 pv2 input-reset ba b--blue bg-blue white pointer f5 br1">Create Sequence</button></HashLink>
          </div>
        </article>

      </section>
    </div>
  );
}

const authCondition = (authUser) => !!authUser;

// export default ProjectPage;
export default withAuthorization(authCondition)(ProjectPage);