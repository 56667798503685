import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';
import { firebase } from '../firebase';
import * as routes from '../constants/routes';

const withAuthorization = (authCondition) => (Component) => {
  const WithAuthorization = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
      firebase.auth.onAuthStateChanged(authUser => {
        if (!authCondition(authUser)) {
          navigate(routes.SIGN_IN);
        }
      });
    }, [firebase, navigate]);

    return (
      <AuthUserContext.Consumer>
        {authUser => authUser ? <Component {...props} /> : null}
      </AuthUserContext.Consumer>
    );
  }

  return WithAuthorization;
}

export default withAuthorization;