import React, { Component } from 'react';
import { hooks } from '../api';

import {
  EuiBasicTable,
  EuiCodeBlock,
  EuiDescriptionList,
  RIGHT_ALIGNMENT,
  EuiButtonIcon,
  EuiSuperDatePicker,
  EuiStat,
  EuiButton
} from '@elastic/eui';

import dateMath from '@elastic/datemath';
import moment from 'moment';

const INITIAL_STATE = {
  projectID: "",
  status: "",
  postAtSortOrder: "desc",
  items: [],
  selectedHookIDs: {},
  itemIdToExpandedRowMap: {},
  pageIndex: 0,
  pageSize: 5,
  totalItemCount: 0,
  selectedItems: [],
  startDate: 'now-1d',
  endDate: 'now+1d',
  isLoading: false,
  isPaused: true,
  refreshInterval: 0,
  recentlyUsedRanges: [],
  showUpdateButton: true,
  isAutoRefreshOnly: false
}

const elasticTimeToMoment = (elasticTime, roundUp = false) => {
  const momentObj = dateMath.parse(elasticTime, {roundUp});
  // dateMath.parse is inconsistent with unparsable strings.
  // Sometimes undefined is returned, other times an invalid moment is returned
  if (!momentObj || !momentObj.isValid()) {
    throw new Error('Unable to parse dateMath string');
  }

  return momentObj;
}

const getRowProps = item => {
  const { id } = item;
  return {
    'data-test-subj': `row-${id}`
  };
};

const getCellProps = (item, column) => {
  const { id } = item;
  const { field } = column;
  return {
    'data-test-subj': `cell-${id}-${field}`,
    textOnly: true
  };
};

class HooksTable extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onUpdateHooks = ({
    projectID = this.state.projectID,
    status = this.state.status,
    postAtSortOrder = this.state.postAtSortOrder,
    pageSize = this.state.pageSize,
    pageIndex = this.state.pageIndex,
    startDate = this.state.startDate,
    endDate = this.state.endDate,
    recentlyUsedRanges = this.state.recentlyUsedRanges,
    selectedItems = this.state.selectedItems,
    force = false
  }) => {
    if (projectID === "") {
      return
    }

    if(
        projectID === this.state.projectID &&
        status === this.state.status &&
        postAtSortOrder === this.state.postAtSortOrder &&
        pageSize === this.state.pageSize &&
        pageIndex === this.state.pageIndex &&
        startDate === this.state.startDate &&
        endDate === this.state.endDate &&
        selectedItems === this.state.selectedItems &&
        force === false
      ) {
      return
    }

    const postAtAfter = elasticTimeToMoment(startDate).format();
    const postAtBefore = elasticTimeToMoment(endDate, {roundUp: true}).format();
    const offset = pageIndex * pageSize;
    const hooksParams = {
      projectID,
      status: status,
      limit: pageSize,
      offset,
      sortOrder: postAtSortOrder,
      postAtAfter,
      postAtBefore,
    };

    hooks.getHooks(hooksParams).then(result => {
      this.setState({
        projectID,
        status,
        items: result.data,
        totalItemCount: result.meta.totalItems,
        pageSize,
        pageIndex,
        startDate: startDate,
        endDate: endDate,
        postAtSortOrder,
        isLoading: false,
        recentlyUsedRanges: recentlyUsedRanges
      })
    }).catch(error => {
      console.error(error);
    });
  }

  toggleDetails = item => {
    const { itemIdToExpandedRowMap } = this.state;
    if (itemIdToExpandedRowMap[item.id]) {
      delete itemIdToExpandedRowMap[item.id];
    } else {
      const { data, failureError, postDurationSeconds } = item;
      const listItems = [
        {
          title: 'Data',
          description: <EuiCodeBlock
            language="json"
            fontSize="s"
            paddingSize="m"
            color="dark"
            overflowHeight={100}
            isCopyable>
            {JSON.stringify(data, undefined, 2)}
          </EuiCodeBlock>,
        },
      ];

      if (postDurationSeconds !== 0) {
        listItems.unshift({
          title: 'Post Duration',
          description: <EuiStat title={`${postDurationSeconds * 1000} ms`}></EuiStat>,
        })
      }

      if(failureError) {
        listItems.unshift({
          title: 'Error',
          description: <EuiCodeBlock language="html">{failureError}</EuiCodeBlock>,
        })
      }

      itemIdToExpandedRowMap[item.id] = (
        <EuiDescriptionList listItems={listItems} />
      );
    }
    this.setState({ itemIdToExpandedRowMap });
  };

  onTableChange = ({ page = {}, sort = {} }) => {
    const { index: pageIndex, size: pageSize } = page;
    const { direction: postAtSortOrder } = sort;

    this.onUpdateHooks({
      projectID: this.state.projectID,
      pageSize,
      pageIndex,
      postAtSortOrder
    });
  };

  onTimeChange = ({ start, end }) => {
    const recentlyUsedRanges = this.state.recentlyUsedRanges.filter(
      recentlyUsedRange => {
        const isDuplicate =
          recentlyUsedRange.start === start && recentlyUsedRange.end === end;
        return !isDuplicate;
      }
    );

    recentlyUsedRanges.unshift({ start, end });

    this.onUpdateHooks({
      startDate: start,
      endDate: end,
      force: true,
      recentlyUsedRanges: recentlyUsedRanges.length > 10
        ? recentlyUsedRanges.slice(0, 9)
        : recentlyUsedRanges
    })
  };

  onSelectionChange = selectedItems => {
    this.setState({ selectedItems });
  };

  renderRetryButton() {
    const { selectedItems } = this.state;

    if (selectedItems.length === 0) {
      return;
    }

    const prefix = this.props.status === 'pending' ? 'Try' : 'Retry';

    return (
      <EuiButton className="mv2" color="secondary" fill iconType="play" size="s" onClick={this.onClickRetryHooks}>
        {
          selectedItems.length > 1 ? `${prefix} ${selectedItems.length} hooks now` : `${prefix} ${selectedItems.length} hook now`
        }
      </EuiButton>
    );
  };

  onClickRetryHooks = () => {
    hooks.retryHooks({
      projectID: this.state.projectID,
      hookIDs: this.state.selectedItems.map((h) => h.id)
    }).then(() => {
      this.onUpdateHooks({
        selectedItems: []
      })
    })
  }

  render() {
    const { items, totalItemCount, itemIdToExpandedRowMap, pageIndex, pageSize, postAtSortOrder } = this.state;
    const retryButton = this.renderRetryButton();

    this.onUpdateHooks({
      projectID: this.props.projectID,
      status: this.props.status,
      postAtSortOrder,
      pageIndex,
      pageSize
    })

    const columns = [
      {
        field: 'id',
        name: 'ID',
        truncateText: true,
        sortable: false,
        footer: ({ pagination }) => (
          <div>
            <span>{pagination.totalItemCount} hooks total in time range</span>
          </div>
        ),
      },
      {
        field: 'path',
        name: 'Path'
      },
      {
        field: 'postAt',
        name: 'Post At',
        sortable: true,
        dataType: 'date',
        render: date => moment(date).format()
      },
      {
        field: 'attempts',
        name: 'Attempts',
        width: '80px',
        align: 'center',
      },
      {
        align: RIGHT_ALIGNMENT,
        width: '40px',
        isExpander: true,
        render: item => (
          <EuiButtonIcon
            onClick={() => this.toggleDetails(item)}
            aria-label={itemIdToExpandedRowMap[item.id] ? 'Collapse' : 'Expand'}
            iconType={itemIdToExpandedRowMap[item.id] ? 'arrowUp' : 'arrowDown'}
          />
        ),
      },
    ];

    const pagination = {
      pageIndex,
      pageSize,
      totalItemCount,
      pageSizeOptions: [5, 20, 100],
      hidePerPageOptions: false,
    };

    const sorting = {
      sort: {
        field: 'postAt',
        direction: postAtSortOrder
      },
    };

    const selection = {
      selectable: () => true,
      onSelectionChange: this.onSelectionChange
    };

    return (
      <div className="euiNamespace dib">
          <EuiSuperDatePicker
            isDisabled={this.state.isDisabled}
            isLoading={this.state.isLoading}
            start={this.state.startDate}
            end={this.state.endDate}
            onTimeChange={this.onTimeChange}
            onRefresh={this.onRefresh}
            isPaused={this.state.isPaused}
            refreshInterval={this.state.refreshInterval}
            onRefreshChange={this.onRefreshChange}
            recentlyUsedRanges={this.state.recentlyUsedRanges}
            showUpdateButton={this.state.showUpdateButton}
            isAutoRefreshOnly={this.state.isAutoRefreshOnly}
          />
        <EuiBasicTable
          items={items}
          itemId="id"
          columns={columns}
          rowProps={getRowProps}
          cellProps={getCellProps}
          responsive={false}
          compressed={false}
          itemIdToExpandedRowMap={itemIdToExpandedRowMap}
          pagination={pagination}
          sorting={sorting}
          selection={selection}
          onChange={this.onTableChange}
          noItemsMessage={"-"}
        />
        {retryButton}
      </div>
    );
  }
};

export default HooksTable;