import { auth } from './firebase';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    updatePassword,
    sendEmailVerification,
    reauthenticateWithCredential,
    signInWithCustomToken,
    updateEmail,
    EmailAuthProvider
} from "firebase/auth";


import { EMAIL_CONTINUE_URL } from '../config';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(auth, email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

// Sign out
export const doSignOut = () =>
    signOut(auth);

// Password Reset
export const doPasswordReset = (email) =>
    sendPasswordResetEmail(auth, email);

// Password Change
export const doPasswordUpdate = (password) =>
    updatePassword(auth.currentUser, password);

// Send Email Verification
export const doSendEmailVerification = () => {
    const actionCodeSettings = {
        url: EMAIL_CONTINUE_URL,
    };

    return sendEmailVerification(auth.currentUser, actionCodeSettings);
}

// Reauth
export const doReauthenticateWithPassword = (password) => {
    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
    );

    return reauthenticateWithCredential(auth.currentUser, credential);
}

export const doReloadAuthUser = () => {
    return auth.currentUser.reload();
}

export const getCurrentUser = () => {
    return auth.currentUser;
}

// Sign in with custom token
export const doSignInWithCustomToken = (token) => {
    return signInWithCustomToken(auth, token);
}

// Update User Email
export const doUpdateCurrentUserEmail = (email) => {
    return updateEmail(auth.currentUser, email);
}