import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';
import withAuthorization from './withAuthorization';
import { auth } from '../firebase';
import PasswordChangeForm from './PasswordChange';

import { subscription, notifications } from '../api';

import Sidebar from './Sidebar';

import * as routes from '../constants/routes';

import Modal from 'react-modal';

const emailRe = /.+@.+\..+/;

const INITIAL_STATE = {
    subscription: {
        hooksQuota: 0,
        hooksCount: 0
    },
    modalIsOpen: false,
    modal: {
        contentLabel: '',
        type: ''
    },
    sentVerificationEmail: false,
    emailVerified: false,
    isManifold: false
}

class AccountPage extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };

        this.openModal = this.openModal.bind(this);
        this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openChangePasswordModal() {
        this.setState({
            modalIsOpen: true,
            modal: {
                contentLabel: "Change Password",
                title: "Change your Password",
                type: 'changePassword'
            }
        })
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.

    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    componentDidMount() {
        subscription.getSubscription().then(result => {
            this.setState({ subscription: result })
        }).catch(error => {
            console.error(error);
        });


        const user = auth.getCurrentUser();
        this.setState({emailVerified: user.emailVerified})

        user.getIdTokenResult()
            .then((idTokenResult) => {
                // Confirm the user is an Admin.
                if (idTokenResult.claims.isManifold) {
                    this.setState({ isManifold: true })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleFocus = (event) => {
        event.target.select();
    }

    onCancelSubscription = (e) => {
        this.setState({
            modalIsOpen: true,
            modal: {
                contentLabel: "Confirm Cancel Subscription",
                title: "Are you sure?",
                type: 'confirmCancelSubscription'
            }
        })
    }

    cancelSubscription = () => {
        subscription.cancelSubscription().then(result => {
            this.setState({
                subscription: result,
                modalIsOpen: false
            });
        }).catch(error => {
            console.error(error);
        });
    }

    onResendVerificationEmail = () => {
        this.setState({ sentVerificationEmail: true });
        auth.doSendEmailVerification().catch(error => {
            console.error(error);
        })
    }

    onCheckEmailVerified = () => {
        auth.doReloadAuthUser().then(() => {
            const user = auth.getCurrentUser();
            this.setState({emailVerified: user.emailVerified});
        }).catch(error => {
            console.error(error);
        })
    }

    render() {
        const { subscription, emailVerified, sentVerificationEmail, isManifold } = this.state;
        const activeSubscription = subscription && (subscription.status === 'active' || subscription.status === 'trialing');

        const trialing = subscription && subscription.status === 'trialing';

        const periodEnd = Date.parse(subscription.periodEnd);
        const now = Date.now();
        const daysLeft = Math.round((periodEnd - now) / (1000 * 60 * 60 * 24));
        let daysLeftText;
        if (activeSubscription) {
            trialing ?
                daysLeftText = `${daysLeft} days remaining in trial period.` :
                daysLeftText = `${daysLeft} days remaining in billing period.`;
        }

        let planDescription;
        if (activeSubscription) {
            subscription.isCancelled ?
                planDescription = `${subscription.planName} (canceled)` :
                planDescription = subscription.planName;
        } else {
            planDescription = `Free`;
        }


        const upgradeLink = activeSubscription ? `${routes.CHECKOUT}?plan=${subscription.planName.toLowerCase()}` : `${routes.CHECKOUT}`
        const modal = this.state.modal;

        const resendVerificationButtonClass = sentVerificationEmail ?
            'b ph3 pv1 button-reset ba b--light-blue bg-light-blue white f6 br1 ma2':
            'b ph3 pv1 button-reset ba b--blue bg-blue white pointer f6 br1 ma2';

        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    <div className="dt-ns w-100 center mw9">
                        <Sidebar />
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            contentLabel={modal.contentLabel}
                            className="avenir center w-100 mw6 mt6"
                        >
                            <div className="pv4 bg-white black pa3 ba br2 b--black shadow-4">
                                <h2 className="tc f4 mid-gray mv0 pv2 ph3">{modal.title}</h2>
                                    { modal.type === 'changePassword' &&
                                        <PasswordChangeForm onSuccess={this.closeModal} />
                                    }
                                    { modal.type === 'confirmDeleteApiKey' &&
                                        <ConfirmDeleteAPIKeyForm onConfirm={this.deleteAPIKey} onCancel={this.closeModal} />
                                    }
                                    { modal.type === 'confirmDeleteNotificationEmail' &&
                                        <ConfirmDeleteNotificationEmailForm onConfirm={this.deleteNotificationEmail} onCancel={this.closeModal} />
                                    }
                                    { modal.type === 'confirmCancelSubscription' &&
                                        <ConfirmCancelSubscriptionForm onConfirm={this.cancelSubscription} onCancel={this.closeModal} />
                                    }
                            </div>
                        </Modal>
                        <section className="pa4 dtc-ns center w-100 w-80-ns mw7">
                            {/* { !emailVerified ?
                                <div className="center tc pa4 bg-light-yellow navy lh-copy">
                                    <span className="lh-title ml3">We need to verify your email address before you can start scheduling hooks. Please check your inbox.</span>
                                    <br/>
                                    <button disabled={sentVerificationEmail} onClick={(e) => this.onResendVerificationEmail(e)} className={resendVerificationButtonClass}>Resend Verification</button>
                                    <button onClick={(e) => this.onCheckEmailVerified(e)} className="b ph3 pv1 button-reset ba b--blue bg-blue white pointer f6 br1 ma2">Already Verified</button>
                                </div> : ''
                            } */}
                            <article className="black center mv4 ba b--black-70 br2 br--top bg-navy shadow-4" id="account">
                                <h2 className="tc f5 white mv0 pv2 ph3">Account</h2>
                                <div className="bg-near-white black pa3 bt b--black-70">
                                    <table className="mw6 f5 w-100" cellSpacing="0">
                                        <tbody className="lh-copy">
                                            <tr>
                                                <td className="pv3 pr3 bn tl">Email:</td>
                                                <td className="pv3 pr3 bn tl">{authUser.email}</td>
                                                <td className="pv3 pr3 bn tl"></td>
                                            </tr>
                                            {!isManifold &&
                                            <tr>
                                                <td className="pv3 pr3 bn tl">Password:</td>
                                                <td className="pv3 pr3 bn tl"><button onClick={this.openChangePasswordModal} className="b ph3 pv1 button-reset ba b--blue bg-blue white pointer f6 br1">Change password...</button></td>
                                                <td className="pv3 pr3 bn tl"></td>
                                            </tr>
                                            }
                                            <tr>
                                                <td className="pv3 pr3 bn tl"></td>
                                                <td className="pv3 pr3 bn tl"></td>
                                                <td className="pv3 pr3 bn tl"></td>
                                            </tr>
                                            <tr>
                                                <td className="pv3 pr3 bn tl">Plan:</td>
                                                <td className="pv3 pr3 bn tl">{ planDescription}
                                                { !isManifold &&
                                                        <Link to={upgradeLink} className="ml3 b ph3 pv1 ba b--green bg-green white f6 br1 link no-underline hover-white">Upgrade</Link>
                                                }
                                                { !isManifold && activeSubscription && !subscription.isCancelled &&
                                                    <button onClick={(e) => this.onCancelSubscription(e)} className="ml3 b ph3 pv1 button-reset ba b--gray bg-gray white pointer f6 br1 link">Cancel</button>
                                                }
                                                </td>
                                                <td className="pv3 pr3 bn tl"></td>
                                            </tr>
                                            <tr>
                                                <td className="pv3 pr3 bn tl b"></td>
                                                <td className="pv3 pr3 bn tl">{daysLeftText}</td>
                                                <td className="pv3 pr3 bn tl"></td>
                                            </tr>
                                            <tr>
                                                <td className="pv3 pr3 bn tl b">Hooks Quota</td>
                                                <td className="pv3 pr3 bn tl"></td>
                                                <td className="pv3 pr3 bn tl"></td>
                                            </tr>
                                            <tr>
                                                <td className="pv3 pr3 bn tl">Allowed:</td>
                                                <td className="pv3 pr3 bn tl">{subscription.hooksQuota}</td>
                                                <td className="pv3 pr3 bn tl"></td>
                                            </tr>
                                            <tr>
                                                <td className="pv3 pr3 bn tl">Remaining:</td>
                                                <td className="pv3 pr3 bn tl">{subscription.hooksQuota - subscription.hooksCount}</td>
                                                <td className="pv3 pr3 bn tl"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </article>
                        </section>
                    </div>
                }
            </AuthUserContext.Consumer>
        );
    }

}

const ConfirmDeleteAPIKeyForm = (props) =>
    <div className="tc center">
        <p className="fw5 lh-copy center f5 tc">Deleting an API Key will stop new Hooks from being scheduled but will not prevent existing ones made with this key from being delivered to your application.</p>
        <button className="dib b ph3 pv2 input-reset ba b--moon-gray bg-moon-gray black pointer f5 br1 tc mh3" onClick={props.onCancel}>Cancel</button>
        <button className="dib b ph3 pv2 input-reset ba b--red bg-red white pointer f5 br1 tc mh3" onClick={props.onConfirm}>Delete</button>
    </div>

const ConfirmDeleteNotificationEmailForm = (props) =>
    <div className="tc center">
        <p className="fw5 lh-copy center f5 tc">Deleting the notification email will prevent Posthook from notifying you when a Hook has failed.</p>
        <button className="dib b ph3 pv2 input-reset ba b--moon-gray bg-moon-gray black pointer f5 br1 tc mh3" onClick={props.onCancel}>Cancel</button>
        <button className="dib b ph3 pv2 input-reset ba b--red bg-red white pointer f5 br1 tc mh3" onClick={props.onConfirm}>Delete</button>
    </div>

const ConfirmCancelSubscriptionForm = (props) =>
    <div className="tc center">
        <p className="fw5 lh-copy center f5 tc">Canceling the subscription will limit your account to the free scheduled hooks allowance starting on the 1st of next month.</p>
        <button className="dib b ph3 pv2 input-reset ba b--moon-gray bg-moon-gray black pointer f5 br1 tc mh3" onClick={props.onCancel}>Dismiss</button>
        <button className="dib b ph3 pv2 input-reset ba b--red bg-red white pointer f5 br1 tc mh3" onClick={props.onConfirm}>Proceed</button>
    </div>

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AccountPage);