import React from 'react';

import { 
  useParams,
} from 'react-router-dom';

import withAuthorization from './withAuthorization';
import Sidebar from './Sidebar';

import SequenceForm from './SequenceForm';


function NewSequencePage() {
  const { project_id } = useParams();

  return (
    <div className="dt-ns w-100 center mw9">
      <Sidebar />
      <section className="pa4 dtc-ns center w-100 w-80-ns mw8">
        <article className="black mv4 ba b--black-70 br2 br--top bg-navy shadow-4 center" id="settings">
          <h2 className="tc f5 white mv0 pv2 ph3">Create New Sequence</h2>
          <div className="bg-near-white black pa3 bt b--black-70">
            <div className="dtc-ns">
              <SequenceForm isNew={true} projectID={project_id} />
            </div>
          </div>
        </article>
      </section>
    </div>
  );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(NewSequencePage);