import { firebase } from '../firebase';
import { API_BASE_URL } from '../config';

import axios from 'axios';

export function addNotificationEmail(projectID, email) {
  return upsertNotification(projectID, 'email', email, 'active', {});
}

export function deleteNotificationChannel(projectID, kind, identifier) {
  return upsertNotification(projectID, kind, identifier, 'deleted', {});
}

export function upsertNotification(projectID, kind, identifier, status, data) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'post',
        url: `${API_BASE_URL}/projects/${projectID}/notifications`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          kind: kind,
          identifier: identifier,
          status: status,
          data: data
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      })
    })
}

export function getNotifications(projectID) {
  return firebase.auth.currentUser.getIdToken()
    .then(token => {
      return axios({
        method: 'get',
        url: `${API_BASE_URL}/projects/${projectID}/notifications`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data.data;
      }).catch((error) => {
        console.error(error);
      })
    })
}
