import React from 'react'
import StatusLink from './StatusLink';

export default () => (
  <footer className="pv4 ph3 ph5-m ph6-l black bg-white bt b--black-10">
    <div className="tc">
      <p className="f6 dib ph2 tc gray mt3">© 2022 Posthook</p>
      <p className="f6 dib ph2 tc gray mt3">Made in NYC</p>
      <a href="/terms" title="Terms of Service" className="f6 dib ph2 link dark-blue dim">Terms & Privacy Policy</a>
      <a href="https://twitter.com/posthook" target="_blank" rel="noopener noreferrer" title="Twitter" className="f6 dib ph2 link dark-blue dim">Twitter</a>
      <a href="/support" title="Contact Us" className="f6 dib ph2 link dark-blue dim">Contact Us</a>
      <StatusLink />
    </div>
  </footer>
)